import React from "react"
import styled from "@emotion/styled"
import { keyframes } from "@emotion/core"

const Spin = keyframes`
0% {
  transform: rotate(0deg);
}
100% {
  transform: rotate(360deg);
}
`
const Container = styled.div`
  display: flex;
  justify-content: center;
`

const Spinner = styled.span`
  border-radius: 50%;
  width: ${props => props.size}rem;
  height: ${props => props.size}rem;
  border: 0.5rem solid #404040;
  border-top-color: #909090;
  animation: ${Spin} 750ms infinite linear;
`

const Loading = ({ size }) => (
  <Container>
    <Spinner size={size} />
  </Container>
)

export default Loading
