import React from "react"
import styled from "@emotion/styled"
import InputText from "./InputText"
import SocialMedia from "./SocialMedia"
import InstagramFeed from "./InstagramFeed"
import TwitterPublish from "./TwitterPublish"
import ShortItemList from "./ShortItemList"
import RightArrow from "../../static/img/svg/rightArrow.svg"

const Container = styled.section`
  padding: 3rem 1.5rem;
  margin: 0 auto;
  width: 100%;
  max-width: 75rem;
`

const SearchForm = styled.form`
  position: relative;
`

const Search = styled.button`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-42%);
  padding: 0 1rem;
  background: transparent;
  border: none;
  cursor: pointer;
`

const Secondary = styled.h4`
  position: relative;
  font-size: 2.5rem;
  font-weight: 400;
  color: #505050;
  margin: 5rem 0 1rem;
  display: inline-block;
  &::after {
    position: absolute;
    content: "";
    height: 2px;
    width: 100%;
    bottom: 0;
    background-color: #8cc949;
    left: 0;
  }
`

const Cta = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
`

const Heading = styled.span`
  font-weight: 700;
  color: #505050;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  span {
    font-weight: 400;
    margin-bottom: 1.5rem;
  }
`

const Image = styled.img`
  object-fit: contain;
  width: 90%;
`

const Svg = styled.img`
  width: 15rem;
`

const SecondaryList = ({ data, handleChange, details }) => (
  <Container>
    <SearchForm action="/blog">
      <InputText
        id="search"
        placeholder="Search"
        type="text"
        name="q"
        color="red"
        margin="normal"
        value={details.q || ""}
        onChange={handleChange}
      />
      <Search aria-label="Search">
        <RightArrow style={{ width: "2rem", fill: "#aaa" }} />
      </Search>
    </SearchForm>

    <div>
      <Secondary>Download our app</Secondary>
      <Cta>
        <Heading>
          Save time <br />
          <span>let us do the dirty work</span>
          <a href="https://apps.apple.com/au/app/return-it-collect/id1474675831">
            <Svg
              src="/img/svg/appStoreBadge.svg"
              aria-label="Download on the App Store"
              alt="App store badge"
            />
          </a>
          <a href="https://play.google.com/store/apps/details?id=au.com.returnit">
            <Svg
              src="/img/svg/googlePlayBadge.svg"
              alt="Google play badge"
              aria-label="Get it on Google Play"
            />
          </a>
        </Heading>
        <Image
          src="/img/uploads/device-1-recollect.png"
          alt="Device screenshot"
        />
      </Cta>
    </div>
    <div>
      <Secondary>Instagram</Secondary>
      <InstagramFeed />
    </div>
    <div>
      <Secondary>Twitter</Secondary>
      <TwitterPublish />
    </div>
    <div>
      <Secondary>Recent Articles</Secondary>
      <ShortItemList data={data} />
    </div>
    <div>
      <Secondary>Find us</Secondary>
      <SocialMedia height={40} textcolor="#505050" />
    </div>
  </Container>
)

export default SecondaryList
