import React, { useState, useEffect } from "react"
import styled from "@emotion/styled"

import Loading from "./Loading"

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(50px, 1fr));
  grid-template-rows: repeat(3, minmax(50px, 1fr));
  grid-gap: 1rem;
`

const Image = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
`

const LoadingWrapper = styled.div`
  grid-column: 1 / -1;
  grid-row: 1 / -1;
  align-self: center;
`

const InstagramFeed = () => {
  const [images, setImages] = useState()

  async function getInstagramData() {
    try {
      let res = await fetch("https://www.instagram.com/recollectaus/?__a=1")
      let data = await res.json()
      setImages(
        data.graphql.user.edge_owner_to_timeline_media.edges.slice(0, 9)
      )
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getInstagramData()
  }, [])
  return (
    <Container>
      {images ? (
        images.map(({ node: { display_url, id, shortcode } }) => (
          <a
            key={id}
            href={`https://www.instagram.com/p/${shortcode}/`}
            target="__blank"
          >
            <Image src={display_url} alt="" />
          </a>
        ))
      ) : (
        <LoadingWrapper>
          <Loading size="5" />
        </LoadingWrapper>
      )}
    </Container>
  )
}

export default InstagramFeed
