import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import styled from "@emotion/styled"

import Layout from "../components/Layout"
import Header from "../components/Header"
import ItemList from "../components/ItemList"
import SecondaryList from "../components/SecondaryList"
import Breadcrumbs from "../components/Breadcrumbs"

const Content = styled.section`
  display: grid;
  grid-template-columns: 2fr 1fr;
  margin: 0 auto;
  max-width: 130rem;
  @media only screen and (max-width: 52em) {
    grid-template-columns: 1fr;
  }
`

export const BlogPageTemplate = ({
  title,
  subtitle,
  breadcrumbs,
  posts,
  query,
  details,
  handleChange,
  isCms
}) => {
  return (
    <>
      <Header
        title={title}
        subtitle={subtitle}
      >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 600 35">
          <path d="M722.704 44.928c-22.669 40.638-155.788 87.065-247.627 103.488-146.175 18.097-228.48 167.189-376.755 133.488C57.31 272.308 66.03 219.14 65.32 191.84c-.863-27.23 11.082-48.226-26.63-83.965C.823 72.205-86.702 21.793-67.03 7.917-47.51-5.888 89.404 4.262 166.263 28.582 242.377 43.086 300.094 5.105 350.465 2.81 400.836.658 450.138 35.58 523.197 18.978c162.24-36.868 207.572-27.21 199.507 25.95z" />
        </svg>
      </Header>
      <Breadcrumbs data={breadcrumbs} />
      {isCms ? null :
        <Content>
          <ItemList data={posts} query={query} />
          <SecondaryList
            data={posts.slice(0, 3)}
            details={details}
            handleChange={handleChange}
          />
        </Content>
      }
    </>
  )
}

const BlogPage = ({
  data: {
    markdownRemark: {
      frontmatter: {
        title, subtitle, tag, seoDesc
      }
    },
    allMarkdownRemark: { edges: posts },
  },
  location,
}) => {
  const [details, setDetails] = useState({})
  const [query, setQuery] = useState()

  useEffect(() => {
    const params = new URLSearchParams(location.search)
    if (params.get("q")) {
      setQuery(params.get("q").toLowerCase())
    }
    setDetails({ ...details, q: query })
  }, [query])

  function handleChange(event) {
    setDetails({ ...details, [event.target.name]: event.target.value })
  }
  const breadcrumbs = location.pathname.split("/")

  return (
    <Layout title={tag} seoDesc={seoDesc}>
      <BlogPageTemplate
        title={title}
        subtitle={subtitle}
        breadcrumbs={breadcrumbs}
        posts={posts}
        query={query}
        details={details}
        handleChange={handleChange}
      />
    </Layout>
  )
}

export default BlogPage

export const pageQuery = graphql`
  query blogQuery {
    markdownRemark(frontmatter: { templateKey: { eq: "blog-page" } }) {
        frontmatter {
            title
            subtitle
            tag
            seoDesc
        }
    }
    allMarkdownRemark(
      filter: {
        frontmatter: { templateKey: { eq: "blog-post" }, publish: { eq: true } }
      }
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            date(formatString: "MMM Do, YYYY")
            tags
            headingImage {
              childImageSharp {
                fluid(maxWidth: 200) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          excerpt(pruneLength: 130)
        }
      }
    }
  }
`
