import React from "react"
import { Link } from "gatsby"
import styled from "@emotion/styled"
import Button from "./Button"

const Container = styled.div`
  text-align: center;
`

const Cta = ({ variant, color, href, children, to, onClick }) => (
  <Container className="cta-container">
    {to ? (
      <Link to={to} style={{ textDecoration: "none" }}>
        <Button variant={variant} color={color} onClick={onClick}>
          {children}
        </Button>
      </Link>
    ) : (
      <a href={href} style={{ textDecoration: "none" }}>
        <Button variant={variant} color={color} onClick={onClick}>
          {children}
        </Button>
      </a>
    )}
  </Container>
)

export default Cta
