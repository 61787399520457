import React from "react"
import { Link } from "gatsby"
import styled from "@emotion/styled"

import Logo from "../../static/img/svg/skipTheDepot-small.svg"

const Container = styled.section`
  width: 100%;
`

const Content = styled.div`
  max-width: 130rem;
  margin: 0 auto;
  padding: 1.5rem 3rem;
  display: flex;
  align-items: center;
`

const StyledLink = styled(Link)`
  color: #bfbfbf;
  text-decoration: none;
  line-height: 1.3;
  text-transform: capitalize;
  padding-right: 1rem;
  font-size: 1.4rem;
  :not(:first-child) {
    &::before {
      content: "\u276F";
      color: #dfdfdf;
      padding-right: 0.75rem;
    }
  }
`

const Breadcrumbs = ({ data }) => {
  const svgStyles = {
    height: "3rem",
    fill: "#cfcfcf",
    paddingTop: ".5rem",
  }

  function toSentenceCase(string) {
    return string.replace(/[_-]+/g, " ")
  }
  let pathname = ""
  return (
    <Container>
      <Content>
        <StyledLink to="/">
          <Logo style={svgStyles} />
        </StyledLink>
        {data &&
          data.map((item, i) => {
            pathname += item + "/"

            if (item.length) {
              return (
                <StyledLink key={i} to={pathname}>
                  {toSentenceCase(decodeURI(item))}
                </StyledLink>
              )
            }
            return null
          })}
      </Content>
    </Container>
  )
}

export default Breadcrumbs
