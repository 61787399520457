import React, { useState, useEffect } from "react"
import styled from "@emotion/styled"

import { Link } from "gatsby"
import Img from "gatsby-image"
import useInfiniteScroll from "../hooks/useInfiniteScroll"
import Cta from "./Cta"

const Container = styled.section`
  max-width: 75rem;
  padding: 3rem 1.5rem;
  margin: 0 auto;
`

const List = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: column;
`

const Item = styled.li`
  transition: all 0.2s;
  &:hover {
    transform: translateY(-5px);
  }
`

const InternalLink = styled(Link)`
  display: grid;
  grid-template-columns: 1fr 150px;
  grid-template-rows: 1fr max-content;
  padding: 1.5rem;
  @media only screen and (max-width: 37.5em) {
    grid-template-columns: 1fr 75px;
  }
  @media only screen and (max-width: 32.5em) {
    padding: 1.5rem 0;
  }
`

const Primary = styled.h4`
  grid-column: 1 / 2;
  font-size: 2.5rem;
  color: #505050;
`

const Secondary = styled.p`
  grid-column: 1 / 2;
  color: #404040;
  text-decoration: none;
  padding: 1rem 1rem 1rem 0;
`

const Created = styled.span`
  grid-column: 1 / 2;
  color: #404040;
  font-size: 1.3rem;
`

const Image = styled(Img)`
  grid-column: 2 / 3;
  grid-row: 1 / -1;
  object-fit: contain;
  height: 100%;
  width: 100%;
`

const ItemList = ({ data, query }) => {
  const [itemCount, setItemCount] = useState(10)
  const [blogItems, setBlogItems] = useState([])
  const [, setIsFetching] = useInfiniteScroll(getItems)

  function getItems() {
    setItemCount(itemCount + 5)
    setIsFetching(false)
  }

  function searchItem(title, tags) {
    const lowerCaseTags = tags.map((tag) => tag.toLowerCase())
    if (
      title.toLowerCase().includes(query) ||
      lowerCaseTags.find((tag) => tag.includes(query))
    ) {
      return true
    }
    return false
  }

  useEffect(() => {
    setBlogItems([])
    if (query) {
      const filteredData = data.filter((post) =>
        searchItem(post.node.frontmatter.title, post.node.frontmatter.tags)
      )
      setBlogItems((arr) => [...arr, ...filteredData])
    } else {
      setBlogItems((arr) => [...arr, ...data])
    }
    return
  }, [query])

  return (
    <Container>
      <List>
        {blogItems.length === 0 ? (
          <div style={{ textAlign: "center" }}>No blog posts found</div>
        ) : (
          blogItems
            .slice(0, itemCount)
            .map(
              ({
                node: {
                  id,
                  fields: { slug },
                  frontmatter: { title, date, headingImage },
                  excerpt,
                },
              }) => (
                <Item key={id}>
                  <InternalLink to={slug} style={{ textDecoration: "none" }}>
                    <Primary>{title}</Primary>
                    <Secondary>{excerpt}</Secondary>
                    <Created>{date}</Created>
                    {headingImage && (
                      <Image fluid={headingImage.childImageSharp.fluid} />
                    )}
                  </InternalLink>
                </Item>
              )
            )
        )}
      </List>
      {itemCount >= blogItems.length ? null : (
        <Cta variant onClick={() => getItems()}>
          Load More
        </Cta>
      )}
    </Container>
  )
}

export default ItemList
