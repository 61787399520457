import React from "react"
import styled from "@emotion/styled"

import { Link } from "gatsby"
import Img from "gatsby-image"

const Container = styled.section`
  margin: 0 auto;
`

const List = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: column;
`

const Item = styled.li`
  transition: all 0.2s;
  &:hover {
    transform: translateY(-5px);
  }
`

const InternalLink = styled(Link)`
  display: grid;
  grid-template-columns: 100px 1fr;
  grid-template-rows: 1fr max-content;
  margin: 1rem 0;
`

const Primary = styled.h4`
  grid-column: 2 / 3;
  color: #505050;
`

const Created = styled.span`
  grid-column: 2 / 3;
  font-size: 1.3rem;
  color: #404040;
`

const Image = styled(Img)`
  grid-column: 1 / 2;
  grid-row: 1 / -1;
  object-fit: contain;
  height: 100%;
  width: 100%;
`

const ShortItemList = ({ data }) => {
  return (
    <Container>
      <List>
        {data &&
          data.map(
            ({
              node: {
                id,
                fields: { slug },
                frontmatter: { title, friendlyTitle, date, headingImage },
                excerpt,
              },
            }) => (
              <Item key={id}>
                <InternalLink to={slug} style={{ textDecoration: "none" }}>
                  <Primary>{friendlyTitle || title}</Primary>

                  {headingImage && (
                    <Image fluid={headingImage.childImageSharp.fluid} />
                  )}
                  <Created>{date}</Created>
                </InternalLink>
              </Item>
            )
          )}
      </List>
    </Container>
  )
}

export default ShortItemList
